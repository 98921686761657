<template>
  <section>
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/images/logo-lowco.png" alt="Lowco logo" />
      </router-link>
    </div>
    <RegisterForm @afterSubmit="goToSuccess" :submit="signUp" mustAgreeTerms />
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import signUpService from '@/services/signUp.service';

import RegisterForm from '../components/Register/RegisterForm.vue';
import routesConstants from '@/constants/routes.constants';

export default defineComponent({
  name: 'Register',
  components: {
    RegisterForm,
  },
  setup() {
    const router = useRouter();

    const goToSuccess = () => {
      router.push(routesConstants.SIGN_UP_SUCCESS);
    };

    return {
      goToSuccess,
      signUp: signUpService.signUp,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/variables.scss";
@import "../assets/styles/common/fonts.scss";
@import "../assets/styles/common/mixins.scss";

@include centeredLayoutWithLogo();
@include centeredLayoutWithLogoForm();
</style>
