import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';
import registerConstants from '@/constants/register.constants';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    case registerConstants.COMPANY_ALREADY_EXIST:
      return registerConstants.COMPANY_ALREADY_EXIST;
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const signUp = (companyInfo) => httpService
  .post(`${BASE_URL}/accounts/companies/sign-up`, companyInfo)
  .catch(throwError);

export default {
  signUp,
};
